import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Modal,
  Box,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import GlobalStateContext from "../context/globalStateContext";
import { COLORS } from "../utils/colors";
import RedClose from "./RedClose";
import { DOCTOR_TYPES, PRICING_PLANS, US_STATES } from "../utils/constants";
import { firebase, firestore } from "../firebaseConfig"; // Update this import
import UserContext from "../context/userContext";
import { guidGenerator } from "../utils/helperMethods";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import * as amplitude from "@amplitude/analytics-browser";
import toast from "react-simple-toasts";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#FFFFFF",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#BBBBBB",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const ScheduleAppointmentModal = () => {
  const theme = useTheme();
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(0);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    preferredDate: "",
    preferredTime: "",
    reason: "",
  });

  const addHours = (date, hours) => {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hours);
    return newDate;
  };

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // 0 is Sunday, 6 is Saturday
  };

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "preferredDate") {
      const selectedDate = new Date(value);
      const minDate = addHours(new Date(), 24);

      // Check if selected date is less than 24 hours from now
      if (selectedDate < minDate) {
        setErrorMessage("Please select a date at least 24 hours from now");
        return;
      }

      // Check if selected date is a weekend
      if (isWeekend(selectedDate)) {
        setErrorMessage("Weekend appointments are not available");
        return;
      }

      setErrorMessage("");
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage("");

    try {
      const selectedDateTime = new Date(
        `${formData.preferredDate} ${formData.preferredTime}`
      );
      const minDateTime = addHours(new Date(), 24);

      // Final validation check
      if (selectedDateTime < minDateTime) {
        throw new Error(
          "Please select a date and time at least 24 hours from now"
        );
      }
      if (isWeekend(selectedDateTime)) {
        throw new Error("Weekend appointments are not available");
      }

      // Save to Firestore using compat syntax
      await firestore.collection("scheduledAppointments").add({
        ...formData,
        activeDoctor: globalState?.activeDoctor,
        status: "pending",
        createdAt: new Date().toISOString(),
        scheduledFor: selectedDateTime.toISOString(),
        patientId: user?.id, // Assuming you have user context
        patientEmail: user?.email,
      });

      // Close modal and show success message
      setGlobalState({ ...globalState, showScheduleModal: false });
      toast("Appointment request submitted successfully!", {
        position: "top-right",
        duration: 3000,
        theme: "dark",
        style: {
          background: "linear-gradient(90deg, #BC90CF, #E384A3)",
          color: "#fff",
          fontWeight: 500,
        },
      });
    } catch (error) {
      setErrorMessage(
        error.message ||
          "Failed to submit appointment request. Please try again."
      );
    }
    setLoading(false);
  };

  // Get minimum date (24 hours from now)
  const minDate = formatDate(addHours(new Date(), 24));

  // Time slots available (9 AM to 5 PM, hourly)
  const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 9; hour <= 17; hour++) {
      const timeString = `${hour.toString().padStart(2, "0")}:00`;
      slots.push(timeString);
    }
    return slots;
  };

  const timeSlots = generateTimeSlots();

  return (
    <Modal
      open={globalState?.showScheduleModal}
      onClose={() =>
        setGlobalState({ ...globalState, showScheduleModal: false })
      }
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#1E1E1E",
          borderRadius: "16px",
          width: isMobile ? "90%" : "500px",
          //   height: isMobile ? "90%" : "70vh",
          padding: "32px",
          boxShadow: "0 4px 30px rgba(0,0,0,0.3)",
          position: "relative",
          overflow: "hidden",
          maxHeight: "90vh",
          overflowY: "auto",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: "niceBlack", // `linear-gradient(90deg, ${COLORS.nicePink}, ${COLORS.niceBlue})`,
          },
          // Custom scrollbar styling
          "&::-webkit-scrollbar": {
            width: "8px",
            background: "rgba(255, 255, 255, 0.05)",
          },
          "&::-webkit-scrollbar-track": {
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: `linear-gradient(180deg, ${COLORS.nicePink}, ${COLORS.niceBlue})`,
            borderRadius: "4px",
            "&:hover": {
              background: `linear-gradient(180deg, ${COLORS.niceBlue}, ${COLORS.nicePurple})`,
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: step === 0 ? "flex-end" : "space-between",
            alignItems: "center",
          }}
        >
          {step === 1 && (
            <Button
              onClick={() => setStep(0)}
              sx={{
                minWidth: "unset",
                p: 1,
                mr: 1,
                color: "#BBBBBB",
                "&:hover": {
                  color: "#FFFFFF",
                },
              }}
            >
              ←
            </Button>
          )}
          <RedClose
            handleClose={() =>
              setGlobalState({ ...globalState, showAddPracticeModal: false })
            }
          />
        </Box>

        <Typography
          variant="h4"
          sx={{ mb: 3, fontWeight: 700, textAlign: "center", color: "#FFFFFF" }}
        >
          {step === 0 ? "Enter details" : "Choose subscription"}
        </Typography>
        {/* STEP 1 - Enter details */}
        {step === 0 && (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}
            >
              <TextField
                required
                fullWidth
                name="name"
                label="Full Name"
                value={formData.name}
                onChange={handleChange}
                sx={{
                  input: { color: "#FFFFFF" },
                  label: { color: "#BBBBBB" },
                }}
              />

              <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                  required
                  fullWidth
                  name="email"
                  type="email"
                  label="Email"
                  value={formData.email}
                  onChange={handleChange}
                  sx={{
                    input: { color: "#FFFFFF" },
                    label: { color: "#BBBBBB" },
                  }}
                />

                <TextField
                  required
                  fullWidth
                  name="phone"
                  label="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  sx={{
                    input: { color: "#FFFFFF" },
                    label: { color: "#BBBBBB" },
                  }}
                />
              </Box>

              <TextField
                required
                fullWidth
                name="preferredDate"
                type="date"
                label="Preferred Date"
                InputLabelProps={{ shrink: true }}
                value={formData.preferredDate}
                onChange={handleChange}
                sx={{
                  input: { color: "#FFFFFF" },
                  label: { color: "#BBBBBB" },
                }}
              />

              <TextField
                required
                fullWidth
                name="preferredTime"
                type="time"
                label="Preferred Time"
                InputLabelProps={{ shrink: true }}
                value={formData.preferredTime}
                onChange={handleChange}
                sx={{
                  input: { color: "#FFFFFF" },
                  label: { color: "#BBBBBB" },
                }}
              />

              <TextField
                required
                fullWidth
                name="reason"
                label="Reason for Visit"
                multiline
                rows={2}
                value={formData.reason}
                onChange={handleChange}
                sx={{
                  textarea: { color: "#FFFFFF" }, // Changed from input to textarea
                  label: { color: "#BBBBBB" },
                  "& .MuiOutlinedInput-root": {
                    // Added to style the outline
                    "& fieldset": {
                      borderColor: "#BBBBBB",
                    },
                    "&:hover fieldset": {
                      borderColor: "#FFFFFF",
                    },
                  },
                }}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading}
                sx={{
                  mt: 2,
                  py: 1.5,
                  fontSize: "1.1rem",
                  background: `linear-gradient(90deg, ${COLORS.nicePink}, ${COLORS.niceBlue})`,
                  color: "#000000",
                  fontWeight: "bold",
                  "&:hover": {
                    background: `linear-gradient(90deg, ${COLORS.niceBlue}, ${COLORS.nicePurple})`,
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Request Appointment"
                )}
              </Button>

              {errorMessage && (
                <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                  {errorMessage}
                </Typography>
              )}

              <Typography
                variant="body2"
                sx={{ mt: 2, textAlign: "center", color: "#BBBBBB" }}
              >
                This is a request to schedule an appointment. Our team at The
                Carnivore Challenge will confirm with the doctor and send you an
                email within 24 hours.
              </Typography>
            </Box>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default ScheduleAppointmentModal;
