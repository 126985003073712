import {
  Google,
  Apple,
  Microsoft,
  Amazon,
  Facebook,
  Twitter,
  LinkedIn,
  Instagram,
  GitHub,
  Airbnb,
} from "@mui/icons-material";

export const STANDARD_BORDER_RADIUS = "12px";
export const CHAT_HEIGHT = "85%";

export const AVATARS = [
  "https://randomuser.me/api/portraits/men/32.jpg",
  "https://randomuser.me/api/portraits/women/44.jpg",
  "https://randomuser.me/api/portraits/men/45.jpg",
  "https://randomuser.me/api/portraits/women/65.jpg",
  "https://randomuser.me/api/portraits/men/22.jpg",
  "https://randomuser.me/api/portraits/women/30.jpg",
  "https://randomuser.me/api/portraits/men/33.jpg",
  "https://randomuser.me/api/portraits/women/40.jpg",
  "https://randomuser.me/api/portraits/men/38.jpg",
  "https://randomuser.me/api/portraits/women/50.jpg",
  "https://randomuser.me/api/portraits/men/52.jpg",
  "https://randomuser.me/api/portraits/women/28.jpg",
  "https://randomuser.me/api/portraits/men/18.jpg",
  "https://randomuser.me/api/portraits/women/24.jpg",
  "https://randomuser.me/api/portraits/men/27.jpg",
  "https://randomuser.me/api/portraits/women/32.jpg",
  "https://randomuser.me/api/portraits/men/41.jpg",
  "https://randomuser.me/api/portraits/women/60.jpg",
];

export const DOCTOR_TYPES = [
  { id: "chiro", name: "Chiropractor" },
  { id: "nutrition", name: "Nutrition" },
  { id: "family-med", name: "Family Medicine" },
  { id: "bariatric", name: "Bariatric Surgery" },
  { id: "internal-med", name: "Internal Medicine" },
  { id: "pediatrics", name: "Pediatrics" },
  { id: "cardiology", name: "Cardiology" },
  { id: "dietitian", name: "Dietitian" },
  { id: "emergency", name: "Emergency Medicine" },
  { id: "clinical-nutrition", name: "Clinical Nutritionist" },
  { id: "endocrinology", name: "Endocrinology" },
  { id: "physical-therapy", name: "Physical Therapist" },
  { id: "geriatrics", name: "Geriatrician" },
  { id: "occupational-med", name: "Occupational Medicine" },
  { id: "nephrology", name: "Nephrology" },
  { id: "obgyn", name: "Obstetrics/Gynecology" },
  { id: "naturopathic", name: "Naturopathic Medicine" },
  { id: "psychiatry", name: "Psychiatry" },
  { id: "urology", name: "Urologist" },
  { id: "integrative", name: "Integrative Medicine" },
  { id: "preventive", name: "Preventive Medicine" },
  { id: "surgery", name: "Surgery" },
  { id: "neurology", name: "Neurologist" },
  { id: "pa", name: "Physician Assistant" },
  { id: "np", name: "Nurse Practitioner" },
  { id: "pmr", name: "Physical Medicine & Rehabilitation" },
  { id: "hormone", name: "Hormone Therapy" },
  { id: "other", name: "Other" },
];

export const PRICING_PLANS = [
  // {
  //   id: "monthly",
  //   name: "1 month",
  //   price: 39,
  //   interval: "month",
  //   description: "Start growing your carnivore-focused practice",
  //   features: [
  //     "Featured listing",
  //     "Verified badge",
  //     "Advanced profile",
  //     "SEO - listed in search engines",
  //   ],
  //   stripePriceId: "price_1QPw39IEp0gdWwV7swVUDCkT", // Replace with actual Stripe Price ID
  // },
  {
    id: "yearly",
    name: "12 months",
    price: 199,
    interval: "year",
    description: "Best value for established carnivore practices",
    features: [
      "Featured listing",
      "Verified badge",
      "Advanced profile",
      "Search Engine Results",
    ],
    stripePriceId: "price_1QPw7BIEp0gdWwV7LqUv0Ofq",
  },
];

export const publicTopics = [
  { id: 1, name: "Startups" },
  { id: 8, name: "Business" },
  { id: 2, name: "History" },
  // { id: 3, name: "Art" },
  // { id: 4, name: "Chemistry" },
  // { id: 5, name: "Physics" },
  // { id: 6, name: "Biology" },
  { id: 3, name: "Sciences" },
  // { id: 7, name: "Math" },
  { id: 6, name: "Others" },
  // Add more topics as needed
];

export const CURRENT_PRICE = 39;

export const YEARLY_PRICING_PLANS = [
  {
    title: "Starter Yearly",
    displayName: "Starter",
    subtitle: "For individual users and small teams",
    price: 220,
    period: "/ yr",
    stripePriceId: "price_1PmgczL1nj8LfslXEJTXcDpY",
    interval: "yearly",
    description: `Billed annually as (3 months free)`,
    features: [
      "Unlimited notes",
      "Visual mind map",
      "Powerful Search",
      "Real-time Team collaboration",
      "Discord community",
      "500 fast premium chat requests",
      "AI-Auto link notes",
      "AI-Chat",
      "Basic support",
    ],
    action: "Get started",
    estCost: 1999,
    estGrossMargin: 201,
    estGrossMarginPercent: 45.6,
    estRevShareAmount: 90.45,
  },
  {
    title: "Pro Yearly",
    displayName: "Professional",
    subtitle: "Everything in Starter, plus...",
    stripePriceId: "price_1PmgdsL1nj8LfslXJb0Pr9iS",
    price: 7499,
    period: "/ yr",
    interval: "yearly",
    description: "Billed annually (3 months free)",
    features: ["1500 fast premium chat requests", "Priority support"],
    popular: true,
    action: "Upgrade",
    estCost: 1200,
    estGrossMargin: 591,
    estGrossMarginPercent: 33.0,
    estRevShareAmount: 265.95,
  },
  // {
  //   title: "Enterprise Yearly",
  //   displayName: "Enterprise",
  //   subtitle: "For large teams and organizations",
  //   price: 8991,
  //   period: "/ yr",
  //   interval: 'yearly',
  //   description: "Billed annually (3 months free)",
  //   features: [
  //     "Up to 15M tokens",
  //     "Hire AI talent (unlimited)",
  //     "Create AI talent (unlimited)",
  //     "Earn revenue from your AI talent",
  //     "Join to Discord community",
  //     "Dedicated Support Person",
  //     "API Access",
  //     "Product Roadmap Input",
  //     "Early Access to New Features",
  //   ],
  //   action: "Contact sales",
  //   estCost: 7200,
  //   estGrossMargin: 1791,
  //   estGrossMarginPercent: 19.9,
  //   estRevShareAmount: 805.95,
  // },
];

export const APP_NAME = "CarnivoreAI";

export const FAQuestions = [
  {
    question: "What is Noted.fm?",
    answer:
      "Noted.fm is a note-taking app designed for visual thinkers. It's built to enhance your creativity and help you organize your thoughts in a more intuitive way.",
  },
  {
    question: "How does the mind map feature work?",
    answer:
      "Our mind map feature allows you to visually connect your notes. You can manually link notes using markdown, or let our AI automatically hyperlink similar notes for you, creating a web of interconnected notes.",
  },
  {
    question: "What AI model does Noted.fm use?",
    answer: `We use ChatGPT, which is currently one of the most powerful AI models available. This allows for advanced features like expanding upon your notes and answering questions about your content.`,
  },
  {
    question: "Is there a free trial available?",
    answer:
      "Yes, we offer a free plan so you can experience the capabilities of Noted.fm before committing to a subscription.",
  },
  {
    question: "Can I use Noted.fm on multiple devices?",
    answer:
      "Not yet, it's just desktop only for now. Mobile coming soon in a month.",
  },
  {
    question: "How secure are my notes?",
    answer:
      "We take the security of your data very seriously. All notes are encrypted and stored securely.",
  },
  {
    question: "Can I collaborate with others on Noted.fm?",
    answer:
      "While our current focus is on individual note-taking, we're exploring collaboration features for future updates. Stay tuned for announcements about shared notebooks and real-time collaboration.",
  },
  {
    question: "How does the AI-powered chat feature work?",
    answer:
      "Our AI chat feature allows you to interact with your notes using natural language. You can ask questions about your content, request summaries, or even brainstorm new ideas based on your existing notes.",
  },
  {
    question: "Is there a limit to how many notes I can create?",
    answer: "Only on the free plan. All paid plans have unlimited notes.",
  },
  {
    question: "Can I export my notes from Noted.fm?",
    answer:
      "Yes, although a manual email process, we will gladly export your notes for you in markdown format.",
  },
];

export const uniLogos = [
  {
    name: "University of California, Berkeley",
    // Icon:
  },
];

export const quotesOfficial = [
  {
    text: "Been searching forever for a nutrition-first cardiologist for my dad. Found one on carnivore.lol!",
    author: "David K, Chicago IL",
  },
  {
    text: "My doctor, I found on here, helped me beat my type 2 diabetes. Thank you!",
    author: "Emma O, Phoenix AZ",
  },
  {
    text: "I cant believe I'm just finding out about this site. I have a new doctor appt scheduled for next week.",
    author: "Sarah B, Austin TX",
  },

  {
    text: "After 3 years of meds, my doctor helped me completely reverse my type 2 diabetes. A1C went from 8.2 to 5.4!",
    author: "Keenan R, Phoenix AZ",
  },
  {
    text: "Finally found a cardiologist who gets it!! My dad's chest pain is gone & hes off 2 of his meds. Been searchin for a nutrition-first doc for almost 4 years!",
    author: "Samantha K, Chicago IL",
  },
  {
    text: "I feel like I can finally trust my doctor.",
    author: "Max C, Sarasota FL",
  },
];

export const quotes = [
  {
    id: 2,
    author: "Jake",
    text: "Tracking my meals has never been easier. This app keeps me motivated and on track with my goals.",
  },
  {
    id: 3,
    author: "Sarah",
    text: "I love the simplicity of this app. It's helped me stay committed to my carnivore journey.",
  },
  {
    id: 4,
    author: "Emily",
    text: "This app is a game-changer! I've lost weight and gained confidence in my diet choices.",
  },
  {
    id: 5,
    author: "Olivia",
    text: "CarnivoreAI app makes meal planning so easy. Highly recommend it to anyone!",
  },
  {
    id: 6,
    author: "Sophia",
    text: "I appreciate how this app supports my lifestyle. It's a perfect fit for my carnivore diet.",
  },
  {
    id: 7,
    author: "Daniella",
    text: "The tracking features are fantastic. It's now an essential part of my daily routine.",
  },
  {
    id: 8,
    author: "Mike",
    text: "This app has made my carnivore journey so much easier. I love seeing my progress!",
  },
];

export const US_STATES = [
  { name: "Alabama", value: "alabama", abbr: "AL" },
  { name: "Alaska", value: "alaska", abbr: "AK" },
  { name: "Arizona", value: "arizona", abbr: "AZ" },
  { name: "Arkansas", value: "arkansas", abbr: "AR" },
  { name: "California", value: "california", abbr: "CA" },
  { name: "Colorado", value: "colorado", abbr: "CO" },
  { name: "Connecticut", value: "connecticut", abbr: "CT" },
  { name: "Delaware", value: "delaware", abbr: "DE" },
  { name: "Florida", value: "florida", abbr: "FL" },
  { name: "Georgia", value: "georgia", abbr: "GA" },
  { name: "Hawaii", value: "hawaii", abbr: "HI" },
  { name: "Idaho", value: "idaho", abbr: "ID" },
  { name: "Illinois", value: "illinois", abbr: "IL" },
  { name: "Indiana", value: "indiana", abbr: "IN" },
  { name: "Iowa", value: "iowa", abbr: "IA" },
  { name: "Kansas", value: "kansas", abbr: "KS" },
  { name: "Kentucky", value: "kentucky", abbr: "KY" },
  { name: "Louisiana", value: "louisiana", abbr: "LA" },
  { name: "Maine", value: "maine", abbr: "ME" },
  { name: "Maryland", value: "maryland", abbr: "MD" },
  { name: "Massachusetts", value: "massachusetts", abbr: "MA" },
  { name: "Michigan", value: "michigan", abbr: "MI" },
  { name: "Minnesota", value: "minnesota", abbr: "MN" },
  { name: "Mississippi", value: "mississippi", abbr: "MS" },
  { name: "Missouri", value: "missouri", abbr: "MO" },
  { name: "Montana", value: "montana", abbr: "MT" },
  { name: "Nebraska", value: "nebraska", abbr: "NE" },
  { name: "Nevada", value: "nevada", abbr: "NV" },
  { name: "New Hampshire", value: "new-hampshire", abbr: "NH" },
  { name: "New Jersey", value: "new-jersey", abbr: "NJ" },
  { name: "New Mexico", value: "new-mexico", abbr: "NM" },
  { name: "New York", value: "new-york", abbr: "NY" },
  { name: "North Carolina", value: "north-carolina", abbr: "NC" },
  { name: "North Dakota", value: "north-dakota", abbr: "ND" },
  { name: "Ohio", value: "ohio", abbr: "OH" },
  { name: "Oklahoma", value: "oklahoma", abbr: "OK" },
  { name: "Oregon", value: "oregon", abbr: "OR" },
  { name: "Pennsylvania", value: "pennsylvania", abbr: "PA" },
  { name: "Rhode Island", value: "rhode-island", abbr: "RI" },
  { name: "South Carolina", value: "south-carolina", abbr: "SC" },
  { name: "South Dakota", value: "south-dakota", abbr: "SD" },
  { name: "Tennessee", value: "tennessee", abbr: "TN" },
  { name: "Texas", value: "texas", abbr: "TX" },
  { name: "Utah", value: "utah", abbr: "UT" },
  { name: "Vermont", value: "vermont", abbr: "VT" },
  { name: "Virginia", value: "virginia", abbr: "VA" },
  { name: "Washington", value: "washington", abbr: "WA" },
  { name: "West Virginia", value: "west-virginia", abbr: "WV" },
  { name: "Wisconsin", value: "wisconsin", abbr: "WI" },
  { name: "Wyoming", value: "wyoming", abbr: "WY" },
];
