import React from "react";

function Privacy() {
  return (
    <div
      style={{
        height: "100vh", // Changed from minHeight to fixed height
        padding: "40px",
        color: "black",
        overflow: "auto", // Add scrolling
      }}
    >
      <h2>1. Information We Collect</h2>
      <h3>a. Information You Provide</h3>
      <p>
        Account Information: When you create an account, we may collect your
        name, email address, username, and password.
      </p>
      <p>
        User Input: Information about the cosmetic products you scan, including
        product details and any custom preferences you provide.
      </p>

      <h3>b. Automatically Collected Information</h3>
      <p>
        Device Information: We collect data about your device, such as device
        type, operating system, browser type, IP address, and unique device
        identifiers.
      </p>
      <p>
        Usage Data: Information about how you interact with the App, including
        access times, pages viewed, and the actions you take.
      </p>
      <p>
        Cookies and Similar Technologies: We use cookies and similar
        technologies to collect data about your interactions with the App.
      </p>

      <h3>c. Third-Party Data</h3>
      <p>
        We may collect information from third-party services you connect to the
        App, such as social media accounts or authentication providers.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>
        To provide the service: Analyze and score cosmetic products using GPT
        Vision API and other algorithms. Personalize recommendations based on
        your preferences.
      </p>
      <p>
        To improve the App: Enhance user experience and app functionality. Debug
        and optimize system performance.
      </p>
      <p>
        For communication: Respond to your inquiries and provide customer
        support. Send important updates, newsletters, and promotional materials
        (where permitted by law).
      </p>
      <p>
        For legal compliance: Comply with applicable laws, regulations, and
        legal obligations. Protect against fraud, unauthorized use, and other
        harmful activities.
      </p>

      <h2>3. How We Share Your Information</h2>
      <p>
        With service providers: Third-party vendors who assist us in providing,
        maintaining, and improving the App (e.g., cloud hosting, analytics,
        customer support).
      </p>
      <p>
        For legal reasons: If required by law, regulation, or legal process. To
        protect our rights, property, or the safety of our users or others.
      </p>
      <p>
        Business transfers: In the event of a merger, acquisition, or sale of
        Company assets, your information may be transferred to the acquiring
        entity.
      </p>
      <p>
        Aggregated or de-identified data: We may share aggregated or
        de-identified data that cannot reasonably identify you for research,
        marketing, or other purposes.
      </p>

      <h2>4. Your Choices</h2>
      <p>
        Access and correction: You may access and update your account
        information directly within the App or by contacting us at [Insert
        Contact Email].
      </p>
      <p>
        Opt-out options: Marketing communications: You can opt out of receiving
        promotional emails by following the unsubscribe instructions in the
        email or contacting us. Cookies: Adjust your browser settings to refuse
        or delete cookies.
      </p>
      <p>
        Data deletion: You may request deletion of your account and personal
        data by contacting us at [Insert Contact Email]. Please note that
        certain data may be retained for legal or business purposes.
      </p>

      <h2>5. Data Security</h2>
      <p>
        We implement technical, administrative, and physical security measures
        to protect your information. However, no method of transmission over the
        internet or electronic storage is 100% secure. While we strive to
        protect your data, we cannot guarantee its absolute security.
      </p>

      <h2>6. Data Retention</h2>
      <p>
        We retain your information for as long as necessary to provide the App
        and fulfill the purposes outlined in this policy, unless a longer
        retention period is required or permitted by law.
      </p>

      <h2>7. Third-Party Links</h2>
      <p>
        The App may contain links to third-party websites or services. We are
        not responsible for the privacy practices of these third parties and
        encourage you to review their privacy policies.
      </p>

      <h2>8. Children’s Privacy</h2>
      <p>
        CarnivoreAI is not intended for children under the age of 13, and we do
        not knowingly collect personal information from children. If we become
        aware that we have collected information from a child under 13, we will
        delete it.
      </p>

      <h2>9. International Users</h2>
      <p>
        If you access the App from outside the United States, please be aware
        that your information may be transferred to and processed in the United
        States or other countries. By using the App, you consent to the transfer
        and processing of your information in accordance with this Privacy
        Policy.
      </p>

      <h2>10. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of significant changes by posting the new policy on the App and updating
        the "Last Updated" date. Your continued use of the App constitutes your
        acceptance of the updated policy.
      </p>

      <h2>11. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>
      <p>
        <strong>OnePace LLC</strong>
      </p>
      <p>[5900 Balcones Drive Ste 100 Austin Texas 78737]</p>
      <p>[onepaceapps@gmail.com]</p>
    </div>
  );
}

export default Privacy;
