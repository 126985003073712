import { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@mui/material";
import styled from "styled-components";
import { COLORS } from "../utils/colors";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const HeaderContainer = styled.header`
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
  width: auto;
  max-width: ${(props) => (props.noLogin ? "100%" : "1200px")};
  margin: 0 auto;
`;

const SignupButton = styled.button`
  background-color: ${COLORS.nicePink};
  color: ${COLORS.niceDeepBlack};
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;

  &:hover {
    background-color: ${COLORS.niceLightPurple};
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(188, 144, 207, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(227, 132, 163, 0.4);
  }
`;

const Header = ({ user, globalState, setGlobalState, noLogin }) => {
  const handleSignupClick = () => {
    setGlobalState({ ...globalState, showSignupModal: true });
  };

  return (
    <HeaderContainer noLogin={noLogin}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          onClick={() => (window.location.href = "/")}
          variant="h6"
          sx={{ ...styles.logo, cursor: "default" }}
        >
          The Carnivore<span style={{ color: COLORS.nicePink }}>Challenge</span>
        </Typography>
        {!noLogin && (
          <SignupButton
            onClick={
              user
                ? () => (window.location.href = "/")
                : () => handleSignupClick()
            }
          >
            {user?.email ? "Go to notes" : "Signin"}
          </SignupButton>
        )}
      </Box>
    </HeaderContainer>
  );
};

const styles = {
  logo: {
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "1.5rem",
    color: COLORS.niceWhite,
    fontFamily: "Arial, sans-serif",
  },
};

export default Header;
