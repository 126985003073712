import React from "react";
import { Box, Typography, Link, Button } from "@mui/material";
import styled, { keyframes } from "styled-components";
import { COLORS } from "../utils/colors";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  background-color: ${COLORS.niceDeepBlack};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${COLORS.niceWhite};
  padding: 2rem;
  position: relative;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  text-align: center;
  z-index: 1;
  animation: ${fadeIn} 0.8s ease-out;
`;

const Title = styled.h1`
  font-size: clamp(2rem, 5vw, 4.5rem);
  font-weight: 800;
  margin-bottom: 1rem;
  line-height: 1.2;
  max-width: 1200px;
  padding: 0 1rem;
`;

const Subtitle = styled.h2`
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  font-weight: 400;
  color: ${COLORS.nicePurple};
  margin-bottom: 2rem;
  opacity: 0.8;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
  line-height: 1.6;
`;

const SupportButton = styled(Button)`
  background: linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  color: ${COLORS.niceWhite};
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  animation: ${fadeIn} 0.8s ease-out 0.6s both;

  &:hover {
    opacity: 0.9;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(188, 144, 207, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(227, 132, 163, 0.4);
  }
`;

const SupportPage = () => {
  return (
    <>
      <Container>
        <MainHeader />
        <ContentWrapper>
          <Title>Support</Title>
          <Subtitle>
            We're here to help! If you have any questions or need assistance,
            please contact us at{" "}
            <Link
              href="mailto:oliver@carnivore.lol"
              sx={{ color: COLORS.nicePink, textDecoration: "none" }}
            >
              oliver@carnivore.lol
            </Link>
          </Subtitle>
          <SupportButton
            onClick={() =>
              (window.location.href = "mailto:oliver@carnivore.lol")
            }
          >
            Contact Support
          </SupportButton>
        </ContentWrapper>
        <Footer />
      </Container>
    </>
  );
};

export default SupportPage;
